import { z } from 'zod';

import { RequestHandler, isFetchError } from '@endaoment-frontend/data-fetching';
import type {
  Address,
  Portfolio,
  PortfolioCategory,
  PortfolioFinancial,
  PortfolioHistoryPoint,
  UUID,
} from '@endaoment-frontend/types';
import {
  addressSchema,
  arraySchemaInvalidsFiltered,
  bigIntSchema,
  entityTypeSchema,
  portfolioCategorySchema,
  portfolioFinancialSchema,
  portfolioHistoryPointSchema,
  portfolioSchema,
  portfolioTradeTypeSchema,
  timestampSchema,
  uuidSchema,
} from '@endaoment-frontend/types';

import { sortPortfolios } from './sortPortfolios';

export const GetAllPortfolios = new RequestHandler(
  'GetAllPortfolios',
  fetch =>
    async (includeDisabled = false): Promise<Array<PortfolioFinancial>> => {
      const res = await fetch('/v1/portfolios', {
        params: { includeDisabled },
        timeout: 10 * 1000,
      });
      const parsed = arraySchemaInvalidsFiltered(portfolioFinancialSchema).parse(res);
      return sortPortfolios(parsed);
    },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/portfolios` }),
  },
);

export const GetPortfolio = new RequestHandler(
  'GetPortfolio',
  fetch =>
    async (id: UUID): Promise<PortfolioFinancial> => {
      const res = await fetch(`/v1/portfolios/${id}`, {
        timeout: 10 * 1000,
      });
      return portfolioFinancialSchema.parse(res);
    },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/portfolios/:id` }),
  },
);

type UpdatePortfolioInput = {
  name?: string;
  description?: string;
  logoUrl?: string;
  enabled?: boolean;
  // TODO: Add API support for updating portfolio cap
  cap?: string;
};

export const UpdatePortfolio = new RequestHandler(
  'UpdatePortfolio',
  fetch =>
    async (id: UUID, data: UpdatePortfolioInput): Promise<Portfolio> =>
      portfolioSchema.parse(await fetch(`/v1/portfolios/${id}`, { method: 'PUT', body: data })),
);

export const GetPortfolioPerformance = new RequestHandler(
  'GetPortfolioPerformance',
  fetch =>
    async (id: UUID): Promise<Array<PortfolioHistoryPoint>> => {
      try {
        const data = await fetch(`/v1/portfolios/${id}/market-history`);
        const withScaledData = z
          .array(portfolioHistoryPointSchema)
          .parse(data)
          .map(p => ({
            // The timestamp being returned is in seconds, but we need milliseconds
            timestamp: p.timestamp * 1000,
            value: p.value,
          }));
        return withScaledData;
      } catch (e) {
        if (isFetchError(e) && e.statusCode === 422) {
          return [];
        }
        throw e;
      }
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/portfolios/:id/market-history`,
    }),
  },
);

const tradeRegistrationResponseSchema = z.object({
  id: uuidSchema,
  portfolioId: uuidSchema,
  issuerEntity: uuidSchema,
  entityType: entityTypeSchema,
  type: portfolioTradeTypeSchema,
  shares: z.string().nullish(),
  dateUtc: timestampSchema,
  /** A USDC value */
  amount: bigIntSchema,
  /** A USDC value */
  fee: bigIntSchema.nullish(),
  transactionHash: addressSchema,
  logIndex: z.number(),
  chainId: z.number(),
});
type TradeRegistrationResponse = z.infer<typeof tradeRegistrationResponseSchema>;
type RegisterTradeBody = {
  tradeTransactionHash: Address;
  chainId: number;
  isUserRebalance?: boolean;
  isAdminRebalance?: boolean;
  recommendationIds?: Array<UUID>;
};
export const RegisterTrade = new RequestHandler(
  'RegisterTrade',
  fetch =>
    async (
      tradeTransactionHash: Address,
      chainId: number,
      isUserRebalance: boolean = false,
      recommendationId?: UUID,
    ): Promise<TradeRegistrationResponse> => {
      const res = await fetch('/v1/trade/register', {
        method: 'POST',
        body: {
          tradeTransactionHash,
          chainId,
          isUserRebalance,
          recommendationIds: recommendationId ? [recommendationId] : [],
        } satisfies RegisterTradeBody,
      });

      const { registeredTrades, pendingTrades } = z
        .object({
          registeredTrades: z.array(tradeRegistrationResponseSchema),
          pendingTrades: z.array(tradeRegistrationResponseSchema),
        })
        .parse(res);

      if (registeredTrades.length + pendingTrades.length > 1)
        throw new Error('Expected exactly one trade to be registered');

      return registeredTrades.length === 0 ? pendingTrades[0] : registeredTrades[0];
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/trade/register` }),
  },
);

export const GetPortfolioCategories = new RequestHandler(
  'GetPortfolioCategories',
  fetch => async (): Promise<Array<PortfolioCategory>> => {
    const res = await fetch('/v1/portfolios/categories');
    return z.array(portfolioCategorySchema).parse(res);
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/portfolios/categories` }),
  },
);

export const GetPortfoliosAvailableToFund = new RequestHandler(
  'GetPortfoliosAvailableToFund',
  fetch =>
    async (fundId: UUID): Promise<Array<PortfolioFinancial>> => {
      const res = await fetch('/v1/portfolios', {
        params: { fundId, includeDisabled: false },
      });
      return sortPortfolios(arraySchemaInvalidsFiltered(portfolioFinancialSchema).parse(res));
    },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/portfolios` }),
  },
);

const portfolioPermissionsResponseSchema = z.object({
  fundIds: z.array(uuidSchema).nullish(),
});
export const GetPortfolioPermissions = new RequestHandler(
  'GetPortfolioPermissions',
  fetch =>
    async (portfolioId: UUID): Promise<z.infer<typeof portfolioPermissionsResponseSchema>> => {
      const res = await fetch(`/v1/portfolios/${portfolioId}/permissions`);
      return portfolioPermissionsResponseSchema.parse(res);
    },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/portfolios/:portfolioId/permissions` }),
  },
);

export const GetIlliquidPortfoliosAvailableToUser = new RequestHandler(
  'GetIlliquidPortfoliosAvailableToUser',
  fetch => async (): Promise<Array<PortfolioFinancial>> => {
    const res = await fetch('/v1/portfolios/illiquid');
    return sortPortfolios(arraySchemaInvalidsFiltered(portfolioFinancialSchema).parse(res));
  },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/portfolios/illiquid` }),
  },
);
